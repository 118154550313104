import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Autoplay, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AssetImage } from '../components/image'
import { useSwiperClass } from '../hooks/useSwiperClass'
import classNames from 'classnames'
import { useBreakpoint } from '../hooks/use-break-point'

const Outer = styled.div`
  position: relative;
`

const slides = [
  {
    img: `top-s-1.png`,
    mobileImg: 'top-sm-1.png'
  },
  {
    img: `top-s-2.png`,
    mobileImg: 'top-sm-2.png'
  },
  {
    img: `top-s-3.png`,
    mobileImg: 'top-sm-3.png'
  },
  // {
  //   img: `top-s-4.png`,
  //   mobileImg: 'top-sm-4.png'
  // },
]
const Slide = styled.div`
`

const ImgContainer = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Controls = styled.div`
  position: absolute;
  right: var(--page-margin);
  bottom: 3rem;
  display: flex;
  z-index: 2;
  gap: 0.75rem;
  @media (max-width: 40rem){
    bottom: 2rem;
  }
`

const ControlItem = styled.div`
  cursor: pointer;
  padding: 0.5rem;
  margin: -0.5rem;
  &::before {
    content: '';
    display: block;
    height: 0.5rem;
    aspect-ratio: 1 / 1;
    width: auto;
    border: 1px solid currentColor;
    border-radius: 10rem;
    box-sizing: border-box;
    opacity: 0.25;
    transition: opacity 0.4s;
  }
  &.active::before {
    opacity: 1;
    background-color: currentColor;
  }
`

type TopSlideSectionProps = {

}
export const TopSlideSection: FunctionComponent<TopSlideSectionProps> = () => {
  const {ref, slideTo, activeIndex} = useSwiperClass()
  const isMobile = useBreakpoint('40rem')
  return <Outer>
    <Swiper
      effect="fade"
      autoplay
      modules={[
        EffectFade,
        Autoplay
      ]}
      onSwiper={ref}
    >
      {
        slides.map((slide, i) => <SwiperSlide key={i}>
          <Slide>
            <ImgContainer>
              <AssetImage src={isMobile ? slide.mobileImg : slide.img} />
            </ImgContainer>
          </Slide>
        </SwiperSlide>)
      }
    </Swiper>
    <Controls>
      {
        slides.map((_, i) => <ControlItem key={i} className={classNames({active: activeIndex === i})} onClick={() => slideTo(i)}></ControlItem>)
      }
    </Controls>
  </Outer>
}