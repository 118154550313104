import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionHead } from '../components/section-header'
import { AssetImage } from '../components/image'
import SvgThinRightArrow from '../generated/svg/thin-right-arrow'
import { pageMaxWidth } from '../styles/page-max-width'
import { Animate } from '../components/animate'
import { AnimateArrow } from '../components/animate-arrow'

const Outer = styled.div``

const Inner = styled.div`
  ${pageMaxWidth}
  display: flex;
  flex-direction: column;
`

const ItemContainer = styled.div`
  margin-left: 5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 3rem;
  @media (max-width: 40rem){
    margin-left: 0;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 2rem;
  }
`

const Item = styled.a`
  display: block;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.4s;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`

const ViewMoreButton = styled.a`
  font-size: 0.875rem;
  line-height: normal;
  letter-spacing: 0.08em;
  align-self: flex-end;
  width: fit-content;
  display: flex;
  gap: 2rem;
  margin-top: 3rem;
  align-items: baseline;
  transition: opacity 0.4s 0.2s;
  opacity: 0;
  .animate & {
    opacity: 1;
  }
  @media (max-width: 40rem){
    font-size: 0.8125rem;
    letter-spacing: 0.08em;
    margin-top: 2rem;
  }
  svg {
    display: block;
    height: 0.5rem;
    width: auto;
    transition: transform 0.4s 0.4s;
    transform: translateX(-1rem);
    .animate & {
      transform: none;
    }
  }
  &:hover svg {
    transition-delay: 0s;
    transform: translateX(0.5rem);
  }
`

type TopInstagramSectionProps = {

}
export const TopInstagramSection: FunctionComponent<TopInstagramSectionProps> = () => {
  return <Outer>
    <Inner>
      <SectionHead>
        Instagram
      </SectionHead>
      <ItemContainer>
        <Item href="https://www.instagram.com/sknremed_official/">
          <AssetImage src="top-instagram-01.png" />
        </Item>
        <Item href="https://www.instagram.com/sknremed_official/">
          <AssetImage src="top-instagram-02.png" />
        </Item>
        <Item href="https://www.instagram.com/sknremed_official/">
          <AssetImage src="top-instagram-03.png" />
        </Item>
        <Item href="https://www.instagram.com/sknremed_official/">
          <AssetImage src="top-instagram-04.png" />
        </Item>
        <Item href="https://www.instagram.com/sknremed_official/">
          <AssetImage src="top-instagram-05.png" />
        </Item>
        <Item href="https://www.instagram.com/sknremed_official/">
          <AssetImage src="top-instagram-06.png" />
        </Item>
        <Item href="https://www.instagram.com/sknremed_official/">
          <AssetImage src="top-instagram-07.png" />
        </Item>
        <Item href="https://www.instagram.com/sknremed_official/">
          <AssetImage src="top-instagram-08.png" />
        </Item>
      </ItemContainer>
      <Animate target="child">
        <ViewMoreButton href="https://www.instagram.com/sknremed_official/">
          VIEW MORE
          <AnimateArrow>
            <SvgThinRightArrow />
          </AnimateArrow>
        </ViewMoreButton>
      </Animate>
    </Inner>
  </Outer>
}