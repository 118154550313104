import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { AssetImage } from '../components/image'
import { pageMaxWidth } from '../styles/page-max-width'
import SvgThinRightArrow from '../generated/svg/thin-right-arrow'
import { Animate } from '../components/animate'
import { AnimateArrow } from '../components/animate-arrow'
import { useSettings } from '../hooks/use-settings'

const Outer = styled.div``

const Inner = styled.div`
`

const ItemContainer = styled.div`
  ${pageMaxWidth}
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.25rem;
  @media (max-width: 40rem){
    grid-template-columns: repeat(3, 18rem);
    gap: 1rem;
    overflow-x: scroll;
  }
`

const Item = styled.a`

`

const ImageContainer = styled.div`
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    transition: transform 0.4s;
    ${Item}:hover &{
      transform: scale(1.05);
    }
  }
`

const Title = styled.div`
  font-size: 1.125rem;
  line-height: normal;
  font-weight: 700;
  margin-top: 2rem;
`

const Content = styled.div`
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`

const ViewMoreButton = styled.a`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  width: max-content;
  gap: 2rem;
  align-items: flex-end;
  margin-top: 2rem;
  font-family: var(--serif-en-font);
  font-weight: 400;;
  margin-right: var(--page-margin);
  transition: opacity 0.4s 0.2s;
  opacity: 0;
  .animate & {
    opacity: 1;
  }
  @media (max-width: 40rem){
    font-size: 0.8125rem;
    gap: 1rem;
  }
  svg {
    display: block;
    height: 0.5rem;
    width: auto;
    transition: transform 0.4s;
    transform: translateX(-1rem);
    .animate & {
      transform: none;
    }
  }
  &:hover svg {
    transition-delay: 0s;
    transform: translateX(0.5rem);
  }
`

type TopTopicsSectionProps = {

}
export const TopTopicsSection: FunctionComponent<TopTopicsSectionProps> = () => {
  const {topics} = useSettings()
  return <Outer>
    <Inner>
      <ItemContainer>
        {
          topics?.slice(0, 3).map((topic, i) => {
            return <Item key={i} href={`/shop/information/${topic.slug}`}>
              <ImageContainer>
                <img src={topic.img} />
              </ImageContainer>
              <Title>
                {topic.title}
              </Title>
              <Content>
                {topic.content}
              </Content>
            </Item>
          })
        }
      </ItemContainer>
      <Animate target="child">
        <ViewMoreButton href="/shop/information_categories/topics">
          VIEW MORE
          <AnimateArrow>
            <SvgThinRightArrow />
          </AnimateArrow>
        </ViewMoreButton>
      </Animate>
    </Inner>
  </Outer>
}