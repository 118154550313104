import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgThinRightArrow from '../generated/svg/thin-right-arrow'
import { SectionHead } from '../components/section-header'
import { pageMaxWidth } from '../styles/page-max-width'
import { Animate } from '../components/animate'
import { AnimateArrow } from '../components/animate-arrow'
import { useSettings } from '../hooks/use-settings'

const Outer = styled.div``

const Inner = styled.div`
  ${pageMaxWidth}
`

const ItemContainer = styled.div`
  margin-right: 5rem;
  margin-left: 5rem;
  margin-top: 4rem;
  border: 1px solid currentColor;
  padding: 2rem 3.75rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  @media (max-width: 40rem){
    margin-left: 0;
    margin-right: 0;
    margin-top: 2rem;
    padding: 1rem;
  }
`

const Item = styled.a`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 3;
  padding: 1.25rem 2rem;
  gap: 0 1.5rem;
  align-items: baseline;
  position: relative;
  @media (max-width: 40rem){
    grid-template-columns: none;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
  }
  & + & {
    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      left: 0;
      top: 0;
      background-color: currentColor;
      transform: scaleX(0);
      transform-origin: 0 0;
      transition: transform;
      .animate & {
        transform: none;
        transition: 0.8s calc(0.2s * var(--index));
      }
    }
  }
`

const ItemDate = styled.div`
  font-family: var(--en-font);
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  opacity: 0;
  transition: opacity;
  .animate & {
    opacity: 1;
    transition: 0.4s calc(0.2s * var(--index) + 0.4s);
  }
  @media (max-width: 40rem){
    font-size: 0.8125rem;
    line-height: normal;
    letter-spacing: 0.08em;
  }
`

const ItemText = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  opacity: 0;
  transition: opacity;
  .animate & {
    opacity: 1;
    transition: 0.4s calc(0.2s * var(--index) + 0.4s);
  }
  @media (max-width: 40rem){
    font-size: 0.8125rem;
    line-height: 1.7307692307692308;
  }
`

const ArrowContainer = styled.div`
  @media (max-width: 40rem){
    align-self: flex-end;
  }
  svg {
    display: block;
    height: 0.5rem;
    width: auto;
  }
`

type TopNewsSectionProps = {

}
export const TopNewsSection: FunctionComponent<TopNewsSectionProps> = () => {
  const {news} = useSettings()
  return <Outer>
    <Inner>
      <SectionHead>
        News
      </SectionHead>
      <Animate target="child">
        <ItemContainer>
          {
            news?.map((item, i) => {
              return <Item key={i} style={{'--index': i}} href={`/shop/information/${item.slug}`}>
                <ItemDate>
                  {item.publishedAt}
                </ItemDate>
                <ItemText>
                  {item.title}
                </ItemText>
                <ArrowContainer>
                  <AnimateArrow>
                    <SvgThinRightArrow />
                  </AnimateArrow>
                </ArrowContainer>
              </Item>
            })
          }
        </ItemContainer>
      </Animate>
    </Inner>
  </Outer>
}