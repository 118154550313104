import * as React from "react";
import type { SVGProps } from "react";
const SvgRightArrowHead = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 11.745 9"
    {...props}
  >
    <path fill="currentcolor" d="M11.746 4.355 0 0l.003 9z" />
  </svg>
);
export default SvgRightArrowHead;
