 import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionHead } from '../components/section-header'
import { SlideWithNavigator } from '../components/slide-with-navigator'
import { AssetImage } from '../components/image'
import { pageMaxWidth } from '../styles/page-max-width'
import { useSwiperClass } from '../hooks/useSwiperClass'
import { SlideControls } from '../components/slide-controls'

const Outer = styled.div`
  overflow-x: clip;
`

const Inner = styled.div`
  ${pageMaxWidth}
`

const SliderContainer = styled.div`
  margin-left: 5rem;
  margin-top: 2rem;
  margin-right: calc(var(--side-margin) * -1);
  @media (max-width: 40rem){
    margin-left: 0;
  }
`

const Label = styled.div`
  font-size: 0.875rem;
  line-height: 1.5714285714285714;
  letter-spacing: 0.08em;
  @media (max-width: 40rem){
    font-size: 0.625rem;
    line-height: 1.5;
    letter-spacing: 0.08em;
  }
`

const Item = styled.div`
  width: 23.25rem;
  @media (max-width: 40rem){
    width: 18rem;
  }
`

const ImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Text1 = styled.div`
  margin-top: 2rem;
  font-weight: 700;
`

const Text2 = styled.div`
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 2;
`

const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
`

type TopClinicSectionProps = {

}
export const TopClinicSection: FunctionComponent<TopClinicSectionProps> = () => {
  const {ref, swiper} = useSwiperClass()
  return <Outer id="clinic">
    <Inner>
      <SectionHead>
        Clinic
      </SectionHead>
      <SliderContainer>
        <SlideWithNavigator
          onSwiper={ref}
          navigatorLabel="取扱クリニック一覧"
          items={[
            {
              leftLabel: <Label>
                Clinic1
              </Label>,
              label: <Label>
                六本木<br />
                スキンクリニック
              </Label>,
              content: <Item>
                <ImageContainer>
                  <AssetImage src="clinic_01.png" />
                </ImageContainer>
                <Text1>
                  六本木スキンクリニック
                </Text1>
                <Text2>
                  〒106-0032<br />
                  東京都港区六本木４丁目１２−１１ タケオカビル 5階<br />
                  03-6455-4658
                </Text2>
              </Item>
            },
            {
              leftLabel: <Label>
                Clinic2
              </Label>,
              label: <Label>
                犀星の杜クリニック<br />
                六本木
              </Label>,
              content: <Item>
                <ImageContainer>
                  <AssetImage src="clinic_02.png" />
                </ImageContainer>
                <Text1>
                  犀星の杜クリニック六本木
                </Text1>
                <Text2>
                  〒106-0032<br />
                  東京都港区六本木７丁目１０−１ M’s BILD 4F-A<br />
                  03-3401-3141
                </Text2>
              </Item>
            },
            {
              leftLabel: <Label>
                Clinic3
              </Label>,
              label: <Label>
                みなと芝<br />
                クリニック
              </Label>,
              content: <Item>
                <ImageContainer>
                  <AssetImage src="clinic_03.png" />
                </ImageContainer>
                <Text1>
                  みなと芝クリニック
                </Text1>
                <Text2>
                  〒105-0014<br />
                  東京都港区芝２丁目１２−１ 桑山ビル 2F<br />
                  03-3457-0555
                </Text2>
              </Item>
            },
            {
              leftLabel: <Label>
                Clinic4
              </Label>,
              label: <Label>
                水道橋ひふ科<br />
                クリニック
              </Label>,
              content: <Item>
                <ImageContainer>
                  <AssetImage src="clinic_04.png" />
                </ImageContainer>
                <Text1>
                  水道橋ひふ科クリニック
                </Text1>
                <Text2>
                  〒113-0033<br />
                  東京都文京区本郷１丁目１４−４ １階<br />
                  03-5684-4112
                </Text2>
              </Item>
            },
            {
              leftLabel: <Label>
                Clinic5
              </Label>,
              label: <Label>
                ハロ<br />
                スキンクリニック
              </Label>,
              content: <Item>
                <ImageContainer>
                  <AssetImage src="clinic_08.png" />
                </ImageContainer>
                <Text1>
                ハロスキンクリニック
                </Text1>
                <Text2>
                  〒150-0021<br />
                  東京都渋谷区恵比寿西２丁目８−１１ グランベル恵比寿Ⅳ 7F<br />
                  03-6824-4732
                </Text2>
              </Item>
            },
            {
              leftLabel: <Label>
                Clinic6
              </Label>,
              label: <Label>
                メディカルアルファ<br />
                クリニック
              </Label>,
              content: <Item>
                <ImageContainer>
                  <AssetImage src="clinic_09.png" />
                </ImageContainer>
                <Text1>
                  メディカルアルファクリニック
                </Text1>
                <Text2>
                  〒542-0081<br />
                  大阪府大阪市中央区南船場４丁目５−８ ラスターオン 6F<br />
                  06-6258-8520
                </Text2>
              </Item>
            },
            {
              leftLabel: <Label>
                Clinic7
              </Label>,
              label: <Label>
                BLANCJOU CLINIC<br />
                블랑쥬의원
              </Label>,
              content: <Item>
                <ImageContainer>
                  <AssetImage src="clinic_05.png" />
                </ImageContainer>
                <Text1>
                  BLANCJOU CLINIC 블랑쥬의원
                </Text1>
                <Text2>
                  서울특별시 서초구 강남대로 445 4층, 5층<br />
                  02-501-3313
                </Text2>
              </Item>
            },
            {
              leftLabel: <Label>
                Clinic8
              </Label>,
              label: <Label>
                IZ CLINIC<br />
                이즈의원
              </Label>,
              content: <Item>
                <ImageContainer>
                  <AssetImage src="clinic_06.png" />
                </ImageContainer>
                <Text1>
                  IZ CLINIC 이즈의원
                </Text1>
                <Text2>
                  서울특별시 강남구 테헤란로115 서림빌딩 5층<br />
                  02-501-3313
                </Text2>
              </Item>
            },
            {
              leftLabel: <Label>
                Clinic9
              </Label>,
              label: <Label>
                SEOUL I PLASTIC<br />
                SURGERY 서울아이성형외과
              </Label>,
              content: <Item>
                <ImageContainer>
                  <AssetImage src="clinic_07.png" />
                </ImageContainer>
                <Text1>
                  SEOUL I PLASTIC SURGERY 서울아이성형외과
                </Text1>
                <Text2>
                  서울 서초구 강남대로415 3층<br />
                  02-253-8575
                </Text2>
              </Item>
            }
          ]}
        />
      </SliderContainer>
    </Inner>
  </Outer>
}