import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { AssetImage } from '../components/image'
import { pageMaxWidth } from '../styles/page-max-width'
import { Dbr } from '../components/br'

const Outer = styled.div``

const ImageContainer1 = styled.div`
  img {
    display: block;
    width: 100%;
    height: 28.125rem;
    object-fit: cover;
  }
`

const Inner = styled.div`
  ${pageMaxWidth}
  display: grid;
  grid-template-columns: 4fr 6fr;
  gap: 4rem;
  margin-top: 7rem;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    margin-top: 2rem;
    gap: 2rem;
  }
`

const TextContainer = styled.div`
  margin-left: 5rem;
  @media (max-width: 40rem){
    margin-left: 0;
  }
`

const Text1 = styled.div`
  font-size: 1.375rem;
  line-height: 1.7272727272727273;
  letter-spacing: 0.08em;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: 1rem;
    line-height: 1.9375;
    letter-spacing: 0;
  }
`

const Text2 = styled.div`
  margin-top: 2.5rem;
  font-size: 0.84375rem;
  line-height: 2.740740740740741;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 2.2857142857142856;
    margin-top: 1.5rem;
  }
`

const ImageContainer2 = styled.div`
  margin-top: 2.5rem;
  @media (max-width: 40rem){
    order: -1;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

type TopSection1Props = {

}
export const TopSection1: FunctionComponent<TopSection1Props> = () => {
  return <Outer>
    <ImageContainer1>
      <AssetImage src="top-14.png" />
    </ImageContainer1>
    <Inner>
      <TextContainer>
        <Text1>
          ドクターズコスメをもっと気軽に<br />
          スキンケアをもっとロジカルに
        </Text1>
        <Text2>
          “ドクターズコスメを使ってみたいけど経済的な理由で続けられない” “高濃度ケアを試してみたいけど肌荒れが不安”<br />
          <br />
          私たちは、美容皮膚科の現場でよく聞くお客様の課題や本当の目的と向き合い、品質と手軽さを両立したドクターズスキンケアを開発しました。<br />
          理論に基づいた今までにない画期的な商品設計と、続けやすい価格設定、通院不要の手軽さを満たす、SKN REMED（スキンリメド）。<br />
          医師が開発した2種類のスキンケア理論を使い、お客さまが求める肌に合わせた商品を、パーソナライズする形で提供することができます。
        </Text2>
      </TextContainer>
      <ImageContainer2>
        <AssetImage src="top-15.png" />
      </ImageContainer2>
    </Inner>
  </Outer>
}