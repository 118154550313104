import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopSlideSection } from '../sections/top-slide-section'
import { TopTopicsSection } from '../sections/top-topics-section'
import { TopConceptSection } from '../sections/top-concept-section'
import { TopCounselingSection } from '../sections/top-counseling-section'
import { TopLineupSection } from '../sections/top-lineup-section'
import { TopSpecialSetSection } from '../sections/top-special-set-section'
import { TopHowToSection } from '../sections/top-how-to-section'
import { TopClinicSection } from '../sections/top-clinic-section'
import { TopSection1 } from '../sections/top-section-1'
import { TopInstagramSection } from '../sections/top-instagram-section'
import { TopNewsSection } from '../sections/top-news-section'
import { VerticalSpacer } from '../components/spacer'
import { FloatingBanner } from '../components/floating-banner'

const Outer = styled.div`
`

type TopPageProps = {

}
export const TopPage: FunctionComponent<TopPageProps> = () => {
  return <Outer>
    <TopSlideSection />
    <VerticalSpacer length='8rem' mLength='5rem' />
    <TopTopicsSection />
    <VerticalSpacer length='8rem' mLength='5rem' />
    <TopConceptSection />
    {/* <VerticalSpacer length='8rem' mLength='0' />
    <TopCounselingSection /> */}
    <VerticalSpacer length='8rem' mLength='5rem' />
    <TopLineupSection showSet/>
    <VerticalSpacer length='8rem' mLength='5rem' />
    <TopSpecialSetSection />
    <VerticalSpacer length='8rem' mLength='5rem' />
    <TopHowToSection />
    <VerticalSpacer length='8rem' mLength='5rem' />
    <TopClinicSection />
    <VerticalSpacer length='8rem' mLength='5rem' />
    <TopSection1 />
    <VerticalSpacer length='8rem' mLength='5rem' />
    <TopInstagramSection />
    <VerticalSpacer length='8rem' mLength='5rem' />
    <TopNewsSection />
    <VerticalSpacer length='4rem' mLength='2rem' />
  </Outer>
}